/* eslint-disable no-console */

// Register push notifications in service worker
self.addEventListener("push", function (event) {
  const data = event.data.json();

  // Optional: log the push event for debugging
  console.log("Received push notification:", data);

  // Default notification settings
  const title = data.title || "New Notification";
  const options = {
    body: data.body || "You have a new message!",
    icon: data.icon || "img/icons/web-app-manifest-192x192.png", // Make sure to adjust the icon location
    badge: data.badge || "img/icons/web-app-manifest-192x192.png", // Provide a badge for the notification
    tag: data.tag || "weather-update", // Useful for grouping notifications
  };

  // Show notification
  event.waitUntil(self.registration.showNotification(title, options));
});

// Handle notification click
self.addEventListener("notificationclick", function (event) {
  event.notification.close();

  // Open the page or route after clicking on the notification
  event.waitUntil(
    clients.openWindow("/#") // Modify this to your desired route or URL
  );
});

// Service Worker installation event to handle caching (for offline support)
self.addEventListener("install", () => {
  console.log("Service Worker installed");
  self.skipWaiting(); // Forces the service worker to take control immediately
});

// Activate event to handle any cleanup during service worker activation
self.addEventListener("activate", (event) => {
  console.log("Service Worker activated");
  event.waitUntil(self.clients.claim());
});

// Listen for push subscription events
self.addEventListener("pushsubscriptionchange", (event) => {
  console.log("Push subscription changed:", event);
  // You can handle re-subscribing here or notify the server to update the subscription
});

// Handle notification close event
self.addEventListener("notificationclose", function (event) {
  console.log("Notification closed:", event.notification);
});
