// src/services/api.js
import axios from "axios";

// Maak een instance van axios met een basis-URL
const api = axios.create({
  baseURL: "https://api.example.com", // Vervang dit met de basis-URL van je API
  timeout: 10000, // Timeout in milliseconden
  headers: {
    "Content-Type": "application/json", // Pas headers aan als dat nodig is
  },
});

// Voeg een response-interceptor toe om foutmeldingen centraal te beheren
api.interceptors.response.use(
  (response) => response, // Als de response succesvol is, geef deze door
  (error) => {
    console.error("API Error:", error.response || error.message); // Log de fout
    return Promise.reject(error); // Zorg ervoor dat de fout wordt doorgegeven
  }
);

export default api;
