import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import "./assets/styles/tailwind.css";
import api from "@/services/api";
import "./registerServiceWorker";
import { createMetaManager } from "vue-meta";

const metaManager = createMetaManager();

const app = createApp(App);

// Voeg de globale API toe
app.config.globalProperties.$api = api;

// Voeg router, i18n, Pinia en vue-meta toe
app.use(router);
app.use(i18n);
app.use(createPinia());
app.use(metaManager);

app.mount("#app");
