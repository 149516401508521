<template>
  <header
    class="bg-primary text-onPrimary py-4 px-6 flex items-center justify-between"
  >
    <div class="flex items-center">
      <a href="/">
        <!-- Dynamisch logo op basis van schermgrootte en grootte -->
        <img :src="logoSrc" :alt="logoAlt" :class="logoClasses" />
      </a>
    </div>

    <div class="flex items-center space-x-4">
      <!-- Taalwisselaar -->
      <select
        v-model="locale"
        class="bg-transparent border-none text-onPrimary"
      >
        <option class="text-onSurface" value="en">EN</option>
        <option class="text-onSurface" value="nl">NL</option>
      </select>
    </div>
  </header>
</template>

<script>
export default {
  name: "LayoutHeader",
  data() {
    return {
      locale: this.$i18n.locale, // Initialiseer de locale op basis van de huidige taal
    };
  },
  computed: {
    // Dynamisch aanpassen van het logo en de afbeeldingseigenschappen op basis van de schermgrootte
    logoSrc() {
      // Logo afhankelijk van schermgrootte
      return window.innerWidth <= 768
        ? "img/icons/favicon-96x96.png" // Mobiele versie van logo
        : "img/icons/web-app-manifest-512x512.png"; // Desktop versie van logo
    },
    logoAlt() {
      return window.innerWidth <= 768 ? "Logo (Mobile)" : "Logo (Desktop)";
    },
    logoClasses() {
      // Dynamisch de hoogte van het logo instellen op basis van schermgrootte
      return window.innerWidth <= 768 ? "h-24 w-auto" : "h-60 w-auto";
    },
  },
  watch: {
    // Als de locale verandert, sla het dan op in de lokale opslag en update de taal
    locale(newLocale) {
      this.$i18n.locale = newLocale;
      localStorage.setItem("appLanguage", newLocale); // Bewaar de taal in localStorage
    },
  },
  mounted() {
    // Zorg ervoor dat de opgeslagen taal uit localStorage wordt geladen bij het opstarten van de app
    const savedLanguage = localStorage.getItem("appLanguage");
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage; // Zet de opgeslagen taal als actieve taal
      this.locale = savedLanguage; // Update de locale in de data
    }
  },
};
</script>
