<template>
  <footer class="bg-surface text-onSurface py-4 px-6 mt-4">
    <div class="text-center text-sm">
      <p>
        &copy; 2024 KrabAlarm.
        <a href="/privacy"> {{ $t("privacyLink") }} </a>. All rights reserved.
      </p>
      <!-- <p>Privacy | Terms of Service</p> -->
      <p>Powered by <a href="http://WeatherAPI.com">WeatherAPI.com</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
};
</script>
