<template>
  <div class="flex flex-col min-h-screen">
    <!-- Header -->
    <Header />

    <!-- Main Content -->
    <main
      class="flex-grow container mx-auto px-4 py-6 overflow-hidden"
      ref="mainContent"
    >
      <slot></slot>
      <!-- Here the page content will load -->
    </main>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/ui/Header.vue";
import Footer from "@/components/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
/* Default height for header */
header {
  height: 120px; /* Default height for larger screens */
}

/* Set the header height to 70px for mobile devices */
@media (max-width: 768px) {
  header {
    height: 70px; /* Adjust height for mobile screens */
  }
}

/* Ensure the footer has a fixed height */
footer {
  height: 60px; /* Adjust based on your design */
}

/* Make sure the main content fits between the header and footer */
main {
  flex-grow: 1;
  overflow-y: auto; /* Prevents content from overflowing */
  max-height: calc(
    100vh - 180px
  ); /* 120px is the combined height of header + footer */
}

@media (max-width: 768px) {
  main {
    max-height: calc(100vh - 130px); /* Adjust for smaller header on mobile */
  }
}
</style>
