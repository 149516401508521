import { createI18n } from "vue-i18n";

// Definieer vertalingen
const messages = {
  en: {
    greeting: "Welcome to Auto Window Checker",
    collapse: "Collapse",
    expand: "Expand",
    searchDescription:
      "Check if you need to scrape your car window! Please search for your city.",
    button: "Allow Notifications",
    settings: {
      title: "Settings",
      language: "Language",
    },
    installApp: "Install app",
    nightCrackingChance: "Morning Cracking Chance:",
    search: "Search",
    lowChance: "Very low chance you'll need to scrape the windshield.",
    smallChance: "There is a small chance of frost tomorrow.",
    moderateChance: "Expect a moderate chance of frost.",
    highChance: "High chance you'll need to scrape the windshield tomorrow.",
    explainerShort:
      "Search for the correct city to check if you need to scrape your window tomorrow.",
    explainerLong:
      "By searching for the correct city, we will calculate the chance that you need to scrape your car window tomorrow morning at 07.00 using an API and a formula. You can prevent this by using a blanket.",
    useLastLocation: "Use last location",
    cookiesMessage:
      "This website uses cookies to enhance the user experience. By continuing, you accept this.",
    cookiesInfo:
      "Cookies are used to remember preferences, gather analytical data, and personalize your experience. Read more in our",
    acceptButton: "Accept",
    privacyLink: "Privacy Policy",

    privacyTitle: "Privacy Policy",
    privacyText1:
      "This website uses cookies to improve your experience. Cookies are used to remember your last visited location, so you can quickly return to the same place during your next visit.",
    privacyText2:
      "Cookies help us remember your preferences and tailor our website to better suit your needs.",
    privacyText3:
      "By continuing to use this website, you agree to the use of cookies as described in this policy.",
    rejectCookies: "Reject Cookies",

    title: "ScrapeAlert",
    home_title: "Home - KrabAlarm",
    home_description:
      "With KrabAlarm, you'll never be caught by surprise again. Always know when it's time to scrape. Always prepared, always frost-free! Ready for the road, every morning!",
    privacy_title: "Privacy - KrabAlarm",
    privacy_description:
      "Read here in detail what we do with your data. It's your choice whether you want to share it.",
    faq: {
      title: "Frequently Asked Questions",
      question1: "Do I need to scrape my windows today?",
      answer1:
        "Whether you need to scrape your windows depends on the weather forecast. Use <strong>KrabAlarm</strong> to check the weather for your location. If the temperature drops below freezing, you're likely to need to scrape your windows.",
      question2: "Will it freeze tonight?",
      answer2:
        "Check the weather forecast in <strong>KrabAlarm</strong> for your location. If the temperature falls below 0°C, it is likely to freeze.",
      question3: "How can I know if I need to scrape my car windows tomorrow?",
      answer3:
        "Use <strong>KrabAlarm</strong> to check the weather forecast for tomorrow and see if you'll need to scrape your car windows. This way, you can plan ahead, like placing an antifreeze cover on your car.",
      question4: "What is the best time to scrape my car windows?",
      answer4:
        "It’s important to take your time when scraping your windows. But prevention is better than cure! Use <strong>KrabAlarm</strong> to plan ahead and use a cover on your car to avoid needing to scrape the windows.",
      question5: "What should I do if I don’t have a scraper?",
      answer5:
        "If you don’t have a scraper, you can try using a plastic bottle or another hard object, but a proper scraper is always the best tool. Check <strong>KrabAlarm</strong> to see when you’ll likely need to scrape your windows so you can be prepared.",
      question6: "Can I scrape my windows with hot water?",
      answer6:
        "It’s not recommended to pour hot water on your windows, as this could cause cracks due to the temperature difference. Check <strong>KrabAlarm</strong> to see if it's safe to scrape your windows based on the weather conditions.",
      question7: "How can I prevent frozen windows in the winter?",
      answer7:
        "Use a blanket or a window cover to prevent your windows from freezing overnight. You can use <strong>KrabAlarm</strong> to plan when to do this.",
      question8: "How long does it take to scrape my windows?",
      answer8:
        "It depends on the thickness of the ice, but it usually shouldn't take longer than 5-10 minutes. With <strong>KrabAlarm</strong>, you can check when you'll have the most ice to scrape.",
      question9:
        "What should I do if I can't scrape due to damage to the windows?",
      answer9:
        "If your windows have damage, like cracks, it's important to seek professional help for repair before using the windows. <strong>KrabAlarm</strong> can help you plan around weather conditions.",
      question10: "Why do my windows freeze every morning?",
      answer10:
        "This can happen due to moisture in the air freezing when the temperature drops below freezing. <strong>KrabAlarm</strong> can help you predict this and plan accordingly.",

      question11: "Can I get a fine for not scraping my windows?",
      answer11:
        "Yes, in some countries and regions, you can be fined for driving with frozen windows, as it obstructs your visibility. Use <strong>KrabAlarm</strong> to plan when you need to scrape your windows, so you can avoid surprises and drive safely.",
    },
    winterforecast_title:
      "Winter Forecast 2024: Is KrabAlarm Your Best Friend This Winter?",
    intro:
      "Winter is just around the corner, and with the cold comes the question: should you scrape your car windows? Whether you're heading to work or taking a morning drive, it's important to be prepared for winter conditions. This year, a handy tool is here to help: KrabAlarm.",
    winter_forecast: "What to Expect from the Winter Forecast?",
    cold_weather_warning:
      "The winter forecast for 2024 predicts colder weather, possibly with snow showers in some regions. It's essential to keep an eye on the latest weather forecast. Snow and ice can quickly cover the roads, causing car windows to freeze. This can be dangerous, so proper preparation is crucial. Whether you've already seen snow or wonder if it's going to snow in the coming days, a snow warning can help you take the right precautions.",
    krabalarm: "KrabAlarm: The Perfect Solution for Icy Mornings",
    krabalarm_description:
      "KrabAlarm is the app that helps you survive the winter. This web app not only provides the current winter forecast but also specifically tells you whether you need to scrape your car windows based on temperature and weather conditions. This way, you can leave the house without worrying about frozen windows. Thanks to KrabAlarm, you'll receive a notification when it's time to clean your windows, so you don't waste precious time.",
    snow_warning: "Snow Warnings and Their Impact",
    snow_warning_description:
      "A snow warning is a notification from meteorological services indicating that snow may fall in the coming hours or days. It's important to keep track of this, especially if you're planning to hit the road. KrabAlarm helps by telling you exactly when you need to scrape your windows — a handy feature for the cold months. Even if it isn't snowing, winter weather can still cause frost to form on your windows, and KrabAlarm makes sure you're aware in time.",
    why_use_krabalarm: "Why Use KrabAlarm?",
    why_use_krabalarm_description:
      "With KrabAlarm, you no longer need to worry about whether you need to scrape your windows or not. The app accurately tracks the weather conditions and sends you a simple notification. This means no more delays, no more wasted time, and especially no more surprises when you head out to your car on a cold morning.",
    call_to_action:
      "Ready to drive without worries this winter? Use KrabAlarm to prepare your car windows for winter weather! Visit the KrabAlarm website, enter your location, and get the most accurate prediction of your winter forecast. Stop wondering about snow or cold, KrabAlarm has the answer! Whether you're driving to work or heading to another city, make sure you're always aware of the latest winter forecast. KrabAlarm will help you with that. Try it today!",
    summary_expl:
      "The winter forecast for this year predicts cold nights and snow showers. This makes it essential to always be well-prepared for frozen car windows. With KrabAlarm, you'll get a timely notification about when to scrape your windows, so you're always safe and on time. Stay informed about snow warnings and other weather conditions with KrabAlarm, and no more worries about icy mornings.",
    summary: "Summary",
    share_this_site: "Share this site:",
    share_facebook: "Facebook",
    share_twitter: "Twitter",
    share_whatsapp: "Whatsapp",
    copy_link: "Copy Link",
    link_copied: "Link copied!",
    share_twitter_message:
      "Check out this amazing site! This way I’ll never be caught off guard again. Ready for the road... Every morning, KrabAlarm",
    share_whatsapp_message:
      "Check out this amazing site! This way I’ll never be caught off guard again. Ready for the road... Every morning, KrabAlarm",
    titlePop: "Help us grow!",
    description:
      "We are an emerging site, and your support means a lot to us. Share the site with your friends or support us with a small donation via Buy Me a Coffee!",
    shortExplainer: "Programming takes a lot of time and effort.",
    longExplainer:
      "Programming takes a lot of time and effort. As a student, I work on this project in my free time. Financial support helps me cover server costs and keeps the project running.",
    readMore: "Read more",
    readLess: "Read less",
    share: "Share",
    donate: "☕ Buy Me a Coffee",
  },
  nl: {
    greeting: "Welkom bij Auto Window Checker",
    collapse: "Inklappen",
    expand: "Uitvouwen",
    searchDescription:
      "Controleer of je je autoraam moet krabben! Zoek je plaats op die je wilt controleren.",
    button: "Sta notificaties toe",
    explainerShort:
      "Zoek naar juiste stad om te kijken of je morgen moet krabben.",
    explainerLong:
      "Door te zoeken naar de juiste stad zullen wij doormiddel van een API en een formule berekenen wat de kans is dat er morgenochtend om 07.00 gekrabt moet worden. Dit kun je natuurlijk voorkomen door een deken te gebruiken.",
    settings: {
      title: "Instellingen",
      language: "Taal",
    },
    installApp: "Installeer app",
    installedReminderExplanation:
      "Na het installeren krijg je dagelijks een melding om 17:00 met de kans dat je morgenochtend je ruiten moet krabben.",
    nightCrackingChance: "Kans op krabben in de ochtend:",
    search: "Zoek",
    lowChance: "Zeer kleine kans dat je de autoruit moet krabben.",
    smallChance: "Er is een kleine kans op rijp morgen.",
    moderateChance: "Verwacht een gematigde kans op rijp.",
    highChance: "Grote kans dat je de autoruit morgen moet krabben.",
    useLastLocation: "Gebruik laatste locatie",
    cookiesMessage:
      "Deze website gebruikt cookies om de gebruikerservaring te verbeteren. Door verder te gaan, accepteer je dit.",
    cookiesInfo:
      "Cookies worden gebruikt om voorkeuren te onthouden, analytische gegevens te verzamelen en om je ervaring te personaliseren. Lees meer in onze",
    acceptButton: "Accepteer",
    privacyLink: "privacyverklaring",
    privacyTitle: "Privacyverklaring",
    privacyText1:
      "Deze website maakt gebruik van cookies om jouw ervaring te verbeteren. Cookies worden gebruikt om je laatst opgezochte locatie te onthouden, zodat je bij een volgend bezoek snel terug kunt gaan naar dezelfde plek.",
    privacyText2:
      "Cookies helpen ons om je voorkeuren te bewaren en om onze website beter af te stemmen op jouw behoeften.",
    privacyText3:
      "Door deze website te blijven gebruiken, ga je akkoord met het gebruik van cookies zoals beschreven in deze verklaring.",
    rejectCookies: "Weiger Cookies",
    title: "KrabAlarm",
    home_title: "Home - KrabAlarm",
    home_description:
      "Met onze KrabAlarm kom je nooit meer voor verrassingen te staan, weet altijd of je moet krabben. Altijd voorbereid, altijd krabvrij!",
    privacy_title: "Privacy - KrabAlarm",
    privacy_description:
      "Lees hier uitgebreid wat we doen met jouw gegevens, aan jou de keuze of je ze wilt delen.",

    faq: {
      title: "Veelgestelde Vragen",
      question1: "Moet ik krabben vandaag?",
      answer1:
        "Of je vandaag moet krabben hangt af van de weersvoorspelling. Gebruik <strong>KrabAlarm</strong> om de weersvoorspelling voor jouw locatie te bekijken. Als de temperatuur onder het vriespunt daalt, is de kans groot dat je je ruiten moet krabben. Zo kun je je voorbereiden en bijvoorbeeld een anti-vries deken op je auto leggen.",
      question2: "Gaat het vriezen vannacht?",
      answer2:
        "Bekijk de weersvoorspelling in <strong>KrabAlarm</strong> voor jouw locatie. Als de temperatuur onder 0°C komt, zal het waarschijnlijk gaan vriezen. Dit helpt je om je auto voor te bereiden en verassingen te voorkomen.",
      question3: "Hoe kan ik weten of ik mijn auto moet krabben morgen?",
      answer3:
        "Gebruik <strong>KrabAlarm</strong> om de weersvoorspelling voor morgen te bekijken en te zien of je auto krabben nodig heeft. Zo kun je bijvoorbeeld een anti-vries deken op je auto leggen of je ruitenkrabber klaarzetten.",
      question4: "Wat is de beste tijd om mijn auto te krabben?",
      answer4:
        "Het is belangrijk om rustig de tijd te nemen als je moet krabben. Maar voorkomen is beter dan genezen. Gebruik <strong>KrabAlarm</strong> om te plannen wanneer het waarschijnlijk gaat vriezen en leg een deken op je auto om te voorkomen dat je moet krabben.",
      question5: "Wat moet ik doen als ik geen ruitenkrabber heb?",
      answer5:
        "Als je geen ruitenkrabber hebt, kun je proberen een plastic fles of een ander hard voorwerp te gebruiken, maar een ruitenkrabber is altijd het beste. Het is handig om in <strong>KrabAlarm</strong> te kijken wanneer je kunt verwachten dat je moet krabben, zodat je voorbereid bent.",
      question6: "Kan ik mijn autoruiten krabben met warm water?",
      answer6:
        "Het is niet aanbevolen om warm water op je ruiten te gieten, omdat dit kan leiden tot barsten door het temperatuurverschil. Raadpleeg <strong>KrabAlarm</strong> om te zien of de temperatuur geschikt is voor krabben en plan de beste tijd.",
      question7: "Hoe voorkom ik bevroren ruiten in de winter?",
      answer7:
        "Gebruik een deken of een ruitenbeschermer om te voorkomen dat de ruiten bevriezen tijdens de nacht. Je kunt <strong>KrabAlarm</strong> gebruiken om te plannen wanneer je dit het beste kunt doen, zodat je de nacht ervoor voorbereid bent.",
      question8: "Hoe lang duurt het om mijn ruiten te krabben?",
      answer8:
        "Dit hangt af van de dikte van het ijs, maar het zou meestal niet langer dan 5-10 minuten moeten duren. Met <strong>KrabAlarm</strong> kun je zien wanneer je te maken hebt met het meeste ijs en kun je je tijd beter inplannen.",
      question9:
        "Wat moet ik doen als ik niet kan krabben vanwege schade aan de ruiten?",
      answer9:
        "Als je schade aan de ruiten hebt, zoals barsten, is het belangrijk om professionele hulp in te schakelen voor reparatie voordat je de ruiten gebruikt. <strong>KrabAlarm</strong> kan je helpen om de weersomstandigheden voor de komende dagen te plannen, zodat je veilig kunt rijden.",
      question10: "Waarom bevriezen mijn ruiten elke ochtend?",
      answer10:
        "Dit kan komen door vocht in de lucht dat bevroren is wanneer de temperatuur onder het vriespunt daalt. Dit is een veelvoorkomend probleem in de wintermaanden. <strong>KrabAlarm</strong> kan je helpen om dit te voorspellen, zodat je je ruiten kunt beschermen of voorbereiden.",
      question11:
        "Kan ik een boete krijgen als ik mijn ruiten niet heb gekrabd?",
      answer11:
        "Ja, in sommige landen en regio's kun je een boete krijgen voor het rijden met bevroren ruiten, omdat het zicht belemmerd wordt. Gebruik <strong>KrabAlarm</strong> om te plannen wanneer je je ruiten moet krabben, zodat je geen verrassingen hebt en veilig kunt rijden.",
    },
    winterforecast_title:
      "Winterverwachting 2024: Is KrabAlarm je beste vriend deze winter?",
    intro:
      "De winter staat voor de deur, en met de kou komt de vraag: moet je je autoruit krabben? Of je nu onderweg naar je werk bent of een ritje maakt in de vroege ochtend, het is belangrijk om voorbereid te zijn op de winterse omstandigheden. Dit jaar komt er een handig hulpmiddel om je te helpen: KrabAlarm.",
    winter_forecast: "Wat kun je verwachten van de winterverwachting?",
    cold_weather_warning:
      "De winterverwachting voor 2024 voorspelt kouder weer, mogelijk met sneeuwbuien in sommige regio’s. Het is dan ook essentieel om de laatste weersverwachting goed in de gaten te houden. Sneeuw en ijzel kunnen plotseling de wegen bedekken, waardoor autoruiten bevriezen. Dit kan gevaarlijk zijn, dus een goede voorbereiding is essentieel. Of je nu al sneeuw hebt zien vallen of je je afvraagt of het in de komende dagen gaat sneeuwen, een sneeuwwaarschuwing kan je helpen om de juiste maatregelen te nemen.",
    krabalarm: "KrabAlarm: De perfecte oplossing voor ijzige ochtenden",
    krabalarm_description:
      "KrabAlarm is de app die jou helpt om de winter te overleven. Deze web app geeft je niet alleen de actuele winterverwachting, maar vertelt je ook specifiek of je je autoruit moet krabben op basis van de temperatuur en de weersomstandigheden. Zo kun je rustig de deur uit zonder je zorgen te maken over bevroren ruiten. Dankzij KrabAlarm krijg je een melding wanneer het tijd is om je autoruit schoon te maken, zodat je geen kostbare tijd verliest.",
    snow_warning: "Sneeuwwaarschuwingen en hun impact",
    snow_warning_description:
      "Een sneeuwwaarschuwing is een waarschuwing van meteorologische diensten die aangeeft dat er in de komende uren of dagen sneeuw kan vallen. Dit is belangrijk om in de gaten te houden, vooral als je van plan bent de weg op te gaan. KrabAlarm helpt je hierbij door precies aan te geven wanneer je je autoruit moet krabben – een handige functie voor de koude maanden. Zelfs als het niet sneeuwt, kan het winterweer ervoor zorgen dat je ruiten bedekt raken met rijp, en KrabAlarm zorgt ervoor dat je op tijd waakzaam bent.",
    why_use_krabalarm: "Waarom KrabAlarm gebruiken?",
    why_use_krabalarm_description:
      "Met KrabAlarm hoef je je geen zorgen meer te maken over of je je autoruit al dan niet moet krabben. De app houdt de weersomstandigheden nauwkeurig bij en informeert je via een simpele melding. Dit betekent geen vertragingen meer, geen verloren tijd, en vooral geen verrassingen meer wanneer je je auto in de vroege ochtend inspeelt.",
    call_to_action:
      "Ben je klaar om deze winter zonder zorgen te rijden? Gebruik KrabAlarm om je autoruit voor te bereiden op het winterweer! Bezoek de website van KrabAlarm, voer je locatie in, en krijg direct de meest accurate voorspelling van je winterverwachting. Stel je geen vragen meer over sneeuw of kou, KrabAlarm geeft je het antwoord! Of je nu met je auto naar de werkplek rijdt of een rit maakt naar een andere stad, zorg ervoor dat je altijd op de hoogte bent van de laatste winterverwachting. KrabAlarm helpt je daarbij. Probeer het vandaag nog!",
    summary_expl:
      "De winterverwachting voor dit jaar voorspelt koude nachten en sneeuwbuien. Dit maakt het essentieel om altijd goed voorbereid te zijn op bevroren autoruiten. Met KrabAlarm krijg je op tijd een melding over wanneer je je ruiten moet krabben, zodat je altijd veilig en zonder vertraging onderweg bent. Blijf op de hoogte van de sneeuwwaarschuwingen en andere weersomstandigheden met KrabAlarm, en maak je geen zorgen meer over ijzige ochtenden.",
    summary: "Samenvatting",
    share_this_site: "Deel deze site:",
    share_facebook: "Facebook",
    share_twitter: "Twitter",
    share_whatsapp: "Whatsapp",
    copy_link: "Kopieer link",
    link_copied: "Link gekopieerd!",
    share_twitter_message:
      "Bekijk deze geweldige site! Hierdoor kom ik nooit meer voor verassingen te staan. Ready for the road... Every morning, KrabAlarm",
    share_whatsapp_message:
      "Bekijk deze geweldige site! Hierdoor kom ik nooit meer voor verassingen te staan. Ready for the road... Every morning, KrabAlarm",
    titlePop: "Help ons groeien!",
    description:
      "We zijn een opkomende site en jouw steun betekent veel voor ons. Deel de site met je vrienden of steun ons met een kleine donatie via Buy Me a Coffee!",
    shortExplainer: "Programmeren kost veel tijd en moeite.",
    longExplainer:
      "Programmeren kost veel tijd en moeite. Als student werk ik in mijn vrije tijd aan dit project. Financiële steun helpt om serverkosten te dekken en het project draaiende te houden.",
    readMore: "Lees meer",
    readLess: "Lees minder",
    share: "Delen",
    donate: "☕ Buy Me a Coffee",
  },
};

// Maak de i18n-instantie
const i18n = createI18n({
  legacy: false, // Zorg ervoor dat je Composition API kunt gebruiken
  locale: "nl", // Standaardtaal is nu Nederlands (nl)
  fallbackLocale: "en", // Fallback-taal is Engels (en)
  messages,
});

export default i18n;
